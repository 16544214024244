import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, Menu, MenuItem, Grid, Badge, useMediaQuery, Alert, AlertTitle } from '@mui/material';
import { AddOutlined, DoneOutlineOutlined } from '@mui/icons-material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import HandymanIcon from '@mui/icons-material/Handyman';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import { Button, ButtonColorProps, ButtonSizeProps, ButtonVariantProps } from '../../../../../components/atoms/Button';
import { SelectMenu } from '../../../../../components/atoms/SelectMenu';
import { Loader } from '../../../../../components/atoms/Loader';
import { ServiceNodeAllocation, ServiceNodeAllocationType } from '../../../../../@types/allocation.type';
import { checkForSelectedRules, getServiceAttributesForServiceCreationForm, statusOptionItems, checkSetParameterNoUIFormAvailability, checkGetGeoLocationFormAvailability, generateServiceURL, checkModifyStructureToggleActiveFormAvailability } from '../../../../../utils/services';
import { ServiceAllocationSelector } from '../../../../../components/molecules/ServiceAllocationSelector';
import { PopupDialog } from '../../../../../components/molecules/PopupDialog';
import { getNewTagsMapped, getSelectedExistingTagsMapped } from '../../../../../components/organisms/Tags';
import { MerchantDamagePartSerialMenuData, PartConditionOptions } from '../../../../../@types/part.type';
import { PendingInteractionFormType } from '../../../../../@types/pendingInteraction.type';
import { GRID_SPACING } from '../../../../../configs/ui-constants';
import { PartActionInstallCard } from '../PartActionInstallCard';
import { FormInterceptor } from '../FormInterceptor';
import { PartActionReplaceCard } from '../PartActionReplaceCard';
import { TaskTimeCard } from '../TaskTimeCard';
import { TaskQuantityCard } from '../TaskQuantityCard';
import { TaskQuoteValueCard } from '../TaskQuoteValueCard';
import { PartActionOtherCard } from '../PartActionOtherCard';
import { PartActionRemoveCard } from '../PartActionRemoveCard';
import { PartActionDoaCard } from '../PartActionDoaCard/PartActionDoaCard';
import { useGetPartActionByServiceId, useGetPartActionTypes } from '../../../../../queries/partAction-query';
import { triggerWarning, getPartActionTypesDropdownData } from '../../../../../utils/part-action';
import { FormInterceptorContext, FormInterceptorContextProps } from '../../../../auth';
import { useModifyStructuresToggleActive, useSubmitFormsDataWithOriginalPayload } from '../../../../../queries/interactions-query';
import { formatDate, formatDateSimple, isDropDownEmpty, isEmptyString } from '../../../../../utils/common';
import {
  useAddService,
  useUpdateParentService,
  useUpdateReportedInformation,
  useUpdateService,
  useUpdateServiceAllocation
} from '../../../../../queries/service-query';
import { useAddTags } from '../../../../../queries/tags-query';
import { useGetTaskByServiceId } from '../../../../../queries/task-query';
import { useCreateStructure, useGetSuggestedNodesFromAllocationEngine } from '../../../../../queries/structure-query';
import { useGetPendingInteractionsByServiceId } from '../../../../../queries/pendingInteraction-query';
import {
  ActionPermissions,
  ActionTriggerFormActionType,
  CustomPermission,
  FormContainerItem,
  FormFieldItem,
  FormFieldType,
  FormObject,
  LocationNodeData,
  MerchantDamageObjectParcer,
  ModifyStructureFormModifyStructureType,
  ParameterDataFormParameterType,
  PartTypeActionLiveSearchSerialiseType,
  PartConditionName,
  PartTypeActionLiveSearchItem,
  ServiceNodeAllocationData, 
  ServiceStatusLabel, 
  Technician, 
  ScreenSize
} from '../../../../../@types';
import { TaskCard, TaskType } from '../../../../../@types/task.type';
import { CheckInCheckOutType, EntityType, ServiceAttachmentUploadFeature, ServiceCancelOrReOpen } from '../../../../../configs/enums';
import { SAMPLE_TASK_CARD, TASK_ITEMS } from '../../../../../constants/task';
import { HttpMethod } from '../../../../../api/http-client';
import { ServiceActionBarProps } from './ServiceActionBar.props';
import {
  ContentPaper,
  IconContainer,
  LightbulbIcon,
  CancelIcon,
  PrimaryAllocationIcon,
  AccountIcon,
  ForwardIcon,
  LocationText,
  LocationSubText,
  AccountText,
  NewButton,
  SuggestedText,
  NotesText,
  SubdirectoryArrowIcon,
  MenuIcon,
  MenuButton,
  SubmitButton,
  SecondaryAllocationIcon,
  ActionBarStack,
  ClickableStack,
  AddButton,
  StyledSelectMenu,
  CloseStatusChangeButton,
  FullWidthBadge,
  FullWidthMenuItem,
  StyledActionBarStack,
  SelectStatusContainer,
  CloseIconWrapStack,
  StyledActionBarAllocateStack,
  ZapSquareIcon,
  FullWidthBadgeInteraction
} from './ServiceActionBar.styles';
import { PartActionType, CustomisePartActionCard, PartActionCardHelperText, PartCreationOptionData, Action } from '../../../../../@types/partAction.type';
import { LOCATION_CANNOT_EMPTY, NOTE_CANNOT_EMPTY, PART_SERIAL_CANNOT_EMPTY, PART_TYPE_CANNOT_EMPTY, SAMPLE_PART_ACTION_CARD_HELPER_TEXT } from '../../../../../constants/partAction';
import { uploadFiles } from '../../../../../api/uploadedFiles';
import { Folders } from '../../../../../@types/uploaded-files.type';
import { useCreateExceptionalPart, useUpdatePartAdditionalData } from '../../../../../queries/part-query';
import { isUserHasPartActionPermissions, isUserHasPermission } from '../../../../../configs/permissions';
import { useGetUserSystemPermissions } from '../../../../../queries/user-query';
import { checkServiceCancelOrFutileAvailability } from '../../../../../utils/service-status';
import { useGetServiceCancelOrReopenStatuses } from '../../../../../queries/service-status-query';
import { checkFieldIsError, checkIsByPassed, getFormIsRepeatableStatus, getSortedPendingInteractions, hasFormFieldValueError } from '../../../../../utils/interactions';
import { getPartTypeActionLiveSearchSerialiseType } from '../../../../../utils/part-type';
import { useCreateShipmentIndividualReceive, useGetShipmentDetailsPartIncludes } from '../../../../../queries/shipment-query';
import { ShipmentManifestSummery } from '../../../../../@types/shipment.type';
import { PartActionShipmentReceive } from '../../../../../components/molecules/PartActionShipmentReceive';
import { useGetUploadedImages } from '../../../../../queries/uploadedfiles-query';
import { ImagePopupSlide } from '../../../../../components/atoms/ImagePopup';
import { PartActionPartCreationDialog, PartImageData } from '../PartActionPartCreationDialog';
import { Snackbar } from '../../../../../components/atoms/Snackbar';
import { useGetServiceLogsByServiceId } from '../../../../../queries/service-log-query';

export const ServiceActionBar: React.FC<ServiceActionBarProps> = ({
  service,
  currentStatusLabel,
  statusOptions,
  statusValue,
  suggestedOptions,
  noteText,
  isCheckInEnabled,
  isCheckOutEnabled,
  technicians,
  isHelpdeskUser,
  primaryAllocation,
  secondaryAllocation,
  partActionCardDataset,
  taskCardDataset,
  onChangeStatusValue,
  onNoteText,
  onCheckIn,
  onCheckOut,
  onAllocationChange,
  onSubmit,
  onChangePartActionCard,
  onSavePartActionCard,
  onExecPartActionCard,
  onChangeTaskCard,
  onSaveTaskCard,
  onExecTaskCard,
  onCloseAndChangePopupTaskCard,
  handlePartActionCardClose,
  partActionData,
  onChangeInterceptedFormsObject,
  closeCheckInCheckOut,
  checkInData,
  checkOutData,
  isCheckIn,
  isCheckOut
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isStatusChange, setIsStatusChange] = useState(false);
  const [isAllocationChange, setIsAllocationChange] = useState(false);
  const [isTask, setIsTask] = useState(false);
  const [isPartActionPopup, setIsPartActionPopup] = useState(false);
  const [isTaskPopup, setIsTaskPopup] = useState(false);
  const [merchantDamageObjectParcer, setMerchantDamageObjectParcer] = useState<MerchantDamageObjectParcer>();
  const [partActionCardType, setPartActionCardType] = useState<PartActionType>(PartActionType.INSTALL);
  const [taskType, setTaskType] = useState<TaskType>(TaskType.LABOUR_TIME);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElTechnicians, setAnchorElTechnicians] = React.useState<null | HTMLElement>(null);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [selectedNodeAllocation, setSelectedNodeAllocation] = useState<ServiceNodeAllocation>();
  const [isPendingInteractions, setIsPendingInteractions] = useState(false);
  const [pendingInteractionFormData, setPendingInteractionFormData] = useState<PendingInteractionFormType[]>();
  const [suggestedNodesFromAllocation, setSuggestedNodesFromAllocation] = useState<LocationNodeData[]>([]);
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [isCancelOrReopen, setIsCancelOrReopen] = useState<string>('');
  const [submitInteractions, setSubmitInteractions] = useState(false);
  const [isPartAction, setIsPartAction] = useState(false);
  const [helperText, setHelperText] = useState<PartActionCardHelperText>(SAMPLE_PART_ACTION_CARD_HELPER_TEXT);
  const [newServiceId, setNewServiceId] = useState<number>(0);
  const [originalInterceptedForms, setOriginalInterceptedForms] = useState<FormObject[]>([]);
  const [repeatableFormData, setRepeatableFormData] = useState({ templateId: 0, templateTitle: '' });
  const [openShipmentReceivePopup, setOpenShipmentReceivePopup] = useState(false);
  const [shipmentManifestSummery, setShipmentManifestSummery] = useState<ShipmentManifestSummery>();
  const [selectedPartActionCard, setSelectedPartActionCard] = useState<CustomisePartActionCard>();
  const [isCheckInCheckOut, setIsCheckInCheckOut] = useState('');
  const [openPartCreationPopup, setOpenPartCreationPopup] = useState(false);
  const [partTypeValue, setPartTypeSearchValue] = useState<PartTypeActionLiveSearchItem>();
  const [partTypeImages, setPartTypeImages] = useState<Array<ImagePopupSlide>>([]);
  const [selectedPartActionData, setSelectedPartActionData] = useState<PartCreationOptionData>();
  const [partImages, setPartImages] = useState<PartImageData>();
  const [showWarningToast, setShowWarningToast] = useState(false);
  const [validate, setValidate] = useState(false);

  const { interceptedFormsObject, setInterceptedFormsObject, hasInterceptedForms, setHasInterceptedForms } = useContext<FormInterceptorContextProps>(FormInterceptorContext);

  const open = Boolean(anchorEl);
  const openTechniciansMenu = Boolean(anchorElTechnicians);

  const isMobileView = useMediaQuery(ScreenSize.MOBILE);

  const getPendingInteractionsByServiceId = useGetPendingInteractionsByServiceId(service.id);
  const addServiceQuery = useAddService();
  const updateParentServiceQuery = useUpdateParentService(service.id);
  const submitFormsDataWithOriginalPayloadQuery = useSubmitFormsDataWithOriginalPayload(service.id);
  const createStructure = useCreateStructure();
  const updateServiceQuery = useUpdateService(service.id);
  const updateServiceAllocationQuery = useUpdateServiceAllocation(service.id);
  const updateReportedInformation = useUpdateReportedInformation();
  const updateTagsQuery = useAddTags();
  const updatePartAdditionalData = useUpdatePartAdditionalData(merchantDamageObjectParcer?.serialData?.partId || 0);
  const getSuggestedNodesFromAllocationEngine = useGetSuggestedNodesFromAllocationEngine(service.serviceTypeCode, service.actionTypeCode, service.processTypeCode, Number(service.brandId !== null ? service.brandId : service.parentId), service.mainLocationNodeId, service.id);
  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const getServiceCancelOrReopenStatuses = useGetServiceCancelOrReopenStatuses(isCancelOrReopen, service.serviceStatusCode, service.serviceTypeCode, service.id);
  const getShipmentDetailsPartIncludes = useGetShipmentDetailsPartIncludes();
  const createShipmentIndividualReceive = useCreateShipmentIndividualReceive(shipmentManifestSummery?.shipmentId || NaN);
  const partTypeImagesQuery = useGetUploadedImages(partTypeValue?.partTypeId || 0, Folders.PartTypesImages);
  const createPartQuery = useCreateExceptionalPart();
  const getPartActionsByServiceId = useGetPartActionByServiceId(service.id);
  const getTasksByServiceIdQuery = useGetTaskByServiceId(service.id);
  const getServiceLogsByServiceIdQuery = useGetServiceLogsByServiceId(service.id);
  const getPartActionTypes = useGetPartActionTypes();
  const modifyStructuresToggleActive = useModifyStructuresToggleActive();

  useEffect(() => {
    setInterceptedFormsObject(undefined);
    setHasInterceptedForms(false);
    setIsPendingInteractions(false);
    setPendingInteractionFormData(undefined);
    setOriginalInterceptedForms([]);
  }, [location]);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    submitInteractions && submitInteractionForms();
  }, [submitInteractions]);

  useEffect(() => {
    if(shipmentManifestSummery && shipmentManifestSummery.shipmentId && !shipmentManifestSummery.receiveEntireShipment) {
      receiveShipment(shipmentManifestSummery.partManifestId);
    }
  }, [shipmentManifestSummery]);

  useEffect(() => {
    if (partTypeValue) {
      partTypeImagesQuery.refetch();
    }
  }, [partTypeValue]);

  useEffect(() => {
    if (partTypeImagesQuery.data) {
      const images: Array<ImagePopupSlide> = partTypeImagesQuery.data.map((obj) => ({
        url: obj.url
      }));
      setPartTypeImages(images);
    }
  }, [partTypeImagesQuery.data]);

  useEffect(() => {
    isPartAction && getPartActionTypes.refetch();
  }, [isPartAction]);

  const checkPartIsInPendingShipment = async (partActionData: CustomisePartActionCard) => {
    const { partId, serial } = partActionData.value;
    const shipmentManifestData = await getShipmentDetailsPartIncludes.mutateAsync({ partId, serviceId: service.id });

    if (shipmentManifestData && shipmentManifestData.shipmentId) {
      setShipmentManifestSummery({ ...shipmentManifestData, serial: serial || '' });
      if(shipmentManifestData.receiveEntireShipment) {
        setSelectedPartActionCard(partActionData);
        setOpenShipmentReceivePopup(true);
        return false;
      }
    }
    return true;
  };

  const receiveShipment = async (serializeManifestId?: number) => {
    if(shipmentManifestSummery?.shipmentId) {
      if(serializeManifestId) {
        const serializeManifest = shipmentManifestSummery.serializedShipmentManifestItem.find(manifest => manifest.manifestId === serializeManifestId);

        if(serializeManifest) {
          serializeManifest.selected = true;
        }
      }
      else {
        shipmentManifestSummery.serializedShipmentManifestItem.map(manifest => manifest.selected = true);
        shipmentManifestSummery.nonSerializedShipmentManifestItem.map(manifest => manifest.selected = true );
      }

      let shipmentNote = `${formatDateSimple(new Date().toString())} Shipment (${shipmentManifestSummery.shipmentId}) received by ${shipmentManifestSummery.siblingNodeName} by scanning Serial ${shipmentManifestSummery.serial} on Service ID ${service.id}.`;
      shipmentNote = `${shipmentManifestSummery.shipmentNote} ${shipmentNote}`.trim();
      await createShipmentIndividualReceive.mutateAsync({ ...shipmentManifestSummery, shipmentNote });
    }
  };

  const handleOnSubmitInteractionForms = async () => {
    let hasErrorFields = false;
    let hasByPassedFields = false;
    const updatedForms: FormObject[] = [];

    // isMandatory & isRequired validation
    interceptedFormsObject?.forms.forEach((form) => {
      const byPassedFormFields: string[] = [];
      const updatedContainers: FormContainerItem[] = [];
      form.containers.forEach((container) => {
        const updatedFields: FormFieldItem[] = [];
        container.fields.forEach((field) => {
          const updatedFieldObject = {
            ...field,
            isError: checkFieldIsError(permissions || [], form, container, field, service)
          };
          hasErrorFields = updatedFieldObject.isError || hasErrorFields;
          updatedFieldObject.isError && field.type === FormFieldType.ServiceCreation && (!container.isDecisionPrompt || container.questionData?.isYes) && setShowWarningToast(true);
          updatedFields.push(updatedFieldObject);
          if (checkIsByPassed(permissions || [], form, container, field, service)) {
            byPassedFormFields.push(field.title || field.type);
            hasByPassedFields = true;
          }
        });
        updatedContainers.push({ ...container, fields: updatedFields });
      });
      updatedForms.push({ ...form, containers: updatedContainers, byPassedFormFields });
    });
    if (hasErrorFields && interceptedFormsObject) {
      setInterceptedFormsObject({ ...interceptedFormsObject, forms: updatedForms });
      pendingInteractionFormData && setPendingInteractionFormData(updatedForms);
      return;
    }
    interceptedFormsObject && hasByPassedFields && setInterceptedFormsObject({ ...interceptedFormsObject, forms: updatedForms });
    setSubmitInteractions(true);
  };

  const submitInteractionForms = async () => {
    if (interceptedFormsObject) {
      setIsFormSubmitting(true);

      let photoKeys: any;
      if (merchantDamageObjectParcer?.serialData?.partId) {
        photoKeys = await uploadFiles(merchantDamageObjectParcer?.serialData?.partId, merchantDamageObjectParcer?.photos, Folders.PartImages);
      }

      // Sub form activities
      const waitForSubFormActivities = new Promise<boolean>((resolve) => {
        (async () => {
          for (const form of interceptedFormsObject.forms) {
            for (const container of form.containers) {
              if (container.isDecisionPrompt && (container.questionData?.hasValue && !container.questionData?.isYes || !container.questionData?.hasValue)) {
                continue;
              }
              for (const field of container.fields) {
                // Create service if a Service Creation Form exist
                if (field.type === FormFieldType.ServiceCreation) {
                  if (field.value) {
                    const newServiceObj = {
                      ...field.value,
                      serviceStatusCode: 'new',
                      serviceTypeCode: field.value.serviceTypeCode,
                      actionTypeCode: field.value.actionTypeCode,
                      processTypeCode: field.value.processTypeCode,
                      clientHierarchyId: field.value.brandId > 0 ? field.value.brandId : field.value.clientId,
                      priorityCode: field.value.priorityCode,
                      subLocationNodeId: field.value.subLocationNodeId,
                      mainLocationNodeId: field.value.mainLocationNodeId,
                      scheduledAt: field.value.scheduledAt ? formatDate(field.value.scheduledAt) : null,
                      notes: field.value.notes,
                      serviceAttributes: getServiceAttributesForServiceCreationForm(field.value),
                      userDepotAllocationNodeId: field.value.userDepotAllocationNodeId,
                      elementForDisplaying: field.value.contactData
                    };

                    if (newServiceObj.priorityCode < 1 || newServiceObj.mainLocationNodeId < 1 || checkForSelectedRules(field.value)) {
                      updateErrorState(form, container, field);
                      return resolve(false);
                    } else {
                      const createdService: any = await addServiceQuery.mutateAsync(newServiceObj);
                      field.value = {
                        ...createdService,
                        id: createdService.id,
                        clientHierarchy: field.value.clientHierarchy,
                        client: field.value.client,
                        contract: field.value.contract,
                        brand: field.value.brand,
                        mainLocationNodeId: field.value.mainLocationNodeId,
                        subLocationNodeId: field.value?.subLocationNodeId,
                        referenceNo1: field.value.referenceNo1,
                        referenceNo2: field.value.referenceNo2,
                        referenceNo3: field.value.referenceNo3,
                        userDepotAllocation: field.value.userDepotAllocation,
                        scheduledAt: formatDate(field.value.scheduledAt, ''),
                        reportedSerialNumber: field.value?.reportedSerialNumber,
                        reportedModel: field.value?.reportedModel,
                        reportedFaultAndDescription: field.value?.reportedFaultAndDescription,
                        notes: field.value.notes,
                        tagCreationData: field.value.tagCreationData
                      };
                      setNewServiceId(createdService.id);

                      if (createdService) {
                        if (field.value.tagCreationData?.selectedTags?.length || field.value.tagCreationData?.newTags?.length) {
                          const existingTags = field.value.tagCreationData?.selectedTags ? getSelectedExistingTagsMapped(field.value.tagCreationData.selectedTags) : [];
                          const freshTags = field.value.tagCreationData?.newTags ? getNewTagsMapped(field.value.tagCreationData.newTags) : [];

                          await updateTagsQuery.mutateAsync({
                            entityTypeId: EntityType.TYPE_SERVICES,
                            entityId: createdService.id,
                            freshTags,
                            existingTags
                          });
                        }

                        await updateParentServiceQuery.mutateAsync({
                          id: service.id,
                          parentServiceId: createdService.id,
                          isChild: true
                        });
                      }
                    }
                  }
                }

                if (field.type === FormFieldType.ServiceCreation && form.byPassedFormFields?.includes(field.title || field.type)) {
                  field.value = undefined;
                }

                // Create sub location or deactivate nodes if a Modify Structure Form exists
                if (field.type === FormFieldType.ModifyStructure) {
                  if (field.preSelectedData?.structureType === ModifyStructureFormModifyStructureType.CreateLocation && !service.subLocationNodeId && !hasFormFieldValueError(field, service)) {
                    if (!field.value?.locationName || isEmptyString(field.value?.locationName)) {
                      updateErrorState(form, container, field);
                      return resolve(false);
                    } else {
                      const newSublocation = await createStructure.mutateAsync({
                        parentNodeId: service.mainLocationNodeId,
                        name: field.value?.locationName,
                        code: field.value?.locationName,
                        nodeTypeCode: field.value.modifyStructure.selectedValue,
                        isActive: true,
                        attributes: []
                      });

                      if (newSublocation) {
                        await updateServiceQuery.mutateAsync({
                          id: service.id,
                          serviceTypeCode: service.serviceTypeCode,
                          serviceStatusCode: service.serviceStatusCode,
                          actionTypeCode: service.actionTypeCode,
                          processTypeCode: service.processTypeCode,
                          nodeId: service.nodeId,
                          priorityCode: service.priorityCode,
                          referenceNo1: service.referenceNo1,
                          subLocationNodeId: newSublocation.id
                        });
                      }
                    }
                  } else if (field.preSelectedData?.structureType === ModifyStructureFormModifyStructureType.ToggleActive) {
                    await modifyStructuresToggleActive.mutateAsync({
                      serviceMainLocationNodeId: service.mainLocationNodeId,
                      serviceSubLocationNodeId: service.subLocationNodeId,
                      toggleActiveType: field.preSelectedData?.selectedValue
                    });
                  }
                }

                // Update parameters if a Parameter Via UI Form exist
                if (field.type === FormFieldType.SetParameter && !hasFormFieldValueError(field, service)) {
                // Status
                  if (field.preSelectedData?.parameterType === ParameterDataFormParameterType.Status) {
                    if (!field.value || isEmptyString(field.value)) {
                      updateErrorState(form, container, field);
                      return resolve(false);
                    } else {
                      await updateServiceQuery.mutateAsync({
                        id: service.id,
                        serviceTypeCode: service.serviceTypeCode,
                        serviceStatusCode: field.value,
                        actionTypeCode: service.actionTypeCode,
                        processTypeCode: service.processTypeCode,
                        nodeId: service.nodeId,
                        priorityCode: service.priorityCode,
                        referenceNo1: service.referenceNo1,
                        subLocationNodeId: service.subLocationNodeId
                      });
                    }
                  }

                  // PrimaryAllocation
                  if (field.preSelectedData?.parameterType === ParameterDataFormParameterType.PrimaryAllocation) {
                    if (!field.value?.id) {
                      updateErrorState(form, container, field);
                      return resolve(false);
                    } else {
                      const allocationData: ServiceNodeAllocationData = {
                        serviceId: service.id,
                        userDepotAllocationNodeId: field.value?.id,
                        allocationTypeCode: ServiceNodeAllocationType.Primary
                      };
                      await updateServiceAllocationQuery.mutateAsync(allocationData);
                    }
                  }

                  // SecondaryAllocation
                  if (field.preSelectedData?.parameterType === ParameterDataFormParameterType.SecondaryAllocation) {
                    if (!field.value?.id) {
                      updateErrorState(form, container, field);
                      return resolve(false);
                    } else {
                      const allocationData: ServiceNodeAllocationData = {
                        serviceId: service.id,
                        userDepotAllocationNodeId: field.value?.id,
                        allocationTypeCode: ServiceNodeAllocationType.Secondary
                      };
                      await updateServiceAllocationQuery.mutateAsync(allocationData);
                    }
                  }

                  // UpdateSubLocation
                  if (field.preSelectedData?.parameterType === ParameterDataFormParameterType.UpdateSubLocation) {
                    if (!field.value?.id) {
                      updateErrorState(form, container, field);
                      return resolve(false);
                    } else {
                      await updateServiceQuery.mutateAsync({
                        id: service.id,
                        serviceTypeCode: service.serviceTypeCode,
                        serviceStatusCode: service.serviceStatusCode,
                        actionTypeCode: service.actionTypeCode,
                        processTypeCode: service.processTypeCode,
                        nodeId: service.nodeId,
                        priorityCode: service.priorityCode,
                        referenceNo1: service.referenceNo1,
                        subLocationNodeId: field.value?.id
                      });
                    }
                  }

                  // ScheduledDate
                  if (field.preSelectedData?.parameterType === ParameterDataFormParameterType.ScheduledDate) {
                    if (!field.value) {
                      updateErrorState(form, container, field);
                      return resolve(false);
                    } else {
                      await updateServiceQuery.mutateAsync({
                        id: service.id,
                        serviceTypeCode: service.serviceTypeCode,
                        serviceStatusCode: service.serviceStatusCode,
                        actionTypeCode: service.actionTypeCode,
                        processTypeCode: service.processTypeCode,
                        nodeId: service.nodeId,
                        priorityCode: service.priorityCode,
                        referenceNo1: service.referenceNo1,
                        subLocationNodeId: service.subLocationNodeId,
                        scheduledAt: formatDate(field.value)
                      });
                    }
                  }

                  // ReportedSerial
                  if (field.preSelectedData?.parameterType === ParameterDataFormParameterType.ReportedSerial) {
                    if (!field.value?.name) {
                      updateErrorState(form, container, field);
                      return resolve(false);
                    } else {
                      await updateReportedInformation.mutateAsync({
                        id: service.id,
                        referenceNo1: service.referenceNo1,
                        referenceNo2: service.referenceNo2,
                        referenceNo3: service.referenceNo3,
                        reportedFaultAndDescriptionId: service.reportedFaultAndDescriptionId,
                        reportedModelId: service.reportedModelId,
                        reportedSerialNumber: field.value?.id // Sending ID is a must in here not name prop
                      });
                    }
                  }

                  // SetTags
                  if (field.preSelectedData?.parameterType === ParameterDataFormParameterType.SetTag) {
                    if ((field.value.selectedTags && field.value.selectedTags.length < 1) && (field.value.newTags && field.value.newTags.length < 1)) {
                      updateErrorState(form, container, field);
                      return resolve(false);
                    } else {
                      const existingTags = getSelectedExistingTagsMapped(field.value.selectedTags);
                      const freshTags = getNewTagsMapped(field.value.newTags);

                      await updateTagsQuery.mutateAsync({
                        entityTypeId: EntityType.TYPE_SERVICES,
                        entityId: service.id,
                        freshTags,
                        existingTags
                      });
                    }
                  }
                }

                if (field.type === FormFieldType.ServiceSearch) {
                  for (const service of field.value) {
                    if (service.isSelected) {
                      service.checkInTime = interceptedFormsObject.payload.checkInTime;
                      service.travelStartTime = interceptedFormsObject.payload.travelStartTime;
                      service.locationNodeId = interceptedFormsObject.payload.locationNodeId;
                      service.notes = interceptedFormsObject.payload.notes || noteText;
                    }
                  }
                }

                if (photoKeys && container.id === merchantDamageObjectParcer?.containerId && field.id === merchantDamageObjectParcer.fieldId) {
                  field.value.photoKeys = Object.values(photoKeys);
                  field.value = JSON.stringify(field.value);
                }
              }
            }
          }

          if (noteText && interceptedFormsObject.payload.note === '') {
            interceptedFormsObject.payload.note = noteText;
          }

          if (isCheckInCheckOut === CheckInCheckOutType.Checkin) {
            interceptedFormsObject.payload.travelStartTime = checkInData.travelStart;
            interceptedFormsObject.payload.notes = checkInData.noteText;
            interceptedFormsObject.payload.selectedServiceIds = checkInData.selectedServiceIds;
          }

          if (isCheckInCheckOut === CheckInCheckOutType.Checkout) {
            interceptedFormsObject.payload.nextStatus = checkOutData.serviceStatus;
            interceptedFormsObject.payload.notes = checkOutData.noteText;
            interceptedFormsObject.payload.checkOutTime = checkOutData.offSiteTime;
          }
        
          // Wait for 1000 milliseconds until the backend gets ready for the interactions
          setTimeout(() => {
            return resolve(true);
          }, 1000);
        })();
      });

      const success = await waitForSubFormActivities;
      if (success) {
        await submitFormsDataWithOriginalPayloadQuery.mutateAsync(interceptedFormsObject);
        if (interceptedFormsObject.actionTriggers?.some((action) => action.preSelectedData?.actionType === ActionTriggerFormActionType.Task)) {
          await getTasksByServiceIdQuery.refetch();
          getTasksByServiceIdQuery.data?.length && setIsTask(true);
        }
        if (interceptedFormsObject.actionTriggers?.some((action) => action.preSelectedData?.actionType !== ActionTriggerFormActionType.Task)) {
          await getPartActionsByServiceId.refetch();
          getPartActionsByServiceId.data?.length && setIsPartAction(true);
        }
        setInterceptedFormsObject(undefined);
        setHasInterceptedForms(false);
        setIsPendingInteractions(false);
        setAnchorEl(null);
        await getPendingInteractionsByServiceId.refetch();
      }
      if (merchantDamageObjectParcer?.serialData?.partId) {
        await updatePartAdditionalData.mutateAsync({
          serviceId: service.id,
          photoKeys: Object.values(photoKeys).toString()
        });
      }
      setIsFormSubmitting(false);
      setSubmitInteractions(false);
      setOriginalInterceptedForms([]);
      setIsStatusChange(false);
      setAnchorEl(null);
      onNoteText('');
      setIsCancelOrReopen('');
      if (isCheckInCheckOut) {
        closeCheckInCheckOut();
        setIsCheckInCheckOut('');
      }
    }
  };

  const updateErrorState = (form: FormObject, container: FormContainerItem, field: FormFieldItem) => {
    if (interceptedFormsObject) {
      const updatedFields: Array<FormFieldItem> = container.fields.map((obj) => obj.id === field.id ? {
        ...field,
        isError: true
      } : obj);

      const updatedContainers: Array<FormContainerItem> = form.containers.map((obj) => obj.id === container.id ? {
        ...container,
        fields: updatedFields
      } : obj);

      const updatedForms = interceptedFormsObject.forms.map((obj) => obj.id === form.id ? {
        ...form,
        containers: updatedContainers
      } : obj);

      setInterceptedFormsObject({ ...interceptedFormsObject, forms: updatedForms });
    }
  };

  const onPopupSave = async () => {
    setIsPartActionPopup(false);
    if (partActionCardDataset && partActionCardDataset.length > 0) {
      const sortedPartActionCards = partActionCardDataset.sort((a, b) => a?.value?.id - b?.value?.id);
      const index = sortedPartActionCards[sortedPartActionCards.length - 1].value.id;

      const initialValue = {
        id: index + 1,
        name: '',
        sw: '',
        hw: '',
        fw: '',
        ft: '',
        serial: '',
        qty: 1,
        condition: '',
        location: '',
        notes: '',
        type: partActionCardType,
        images: []
      };

      onChangePartActionCard([...partActionCardDataset, {
        value: initialValue,
        inValue: initialValue,
        outValue: initialValue
      }]);
    } else {
      const initialValue = {
        id: 1,
        name: '',
        sw: '',
        hw: '',
        fw: '',
        ft: '',
        serial: '',
        qty: 1,
        condition: '',
        location: '',
        notes: '',
        type: partActionCardType,
        images: []
      };
      onChangePartActionCard([...partActionCardDataset,
        {
          value: initialValue,
          inValue: initialValue,
          outValue: initialValue
        }
      ]);
    }
  };

  const onPopupTaskSave = async () => {
    setIsTaskPopup(false);
    let newTaskCard: TaskCard = SAMPLE_TASK_CARD;
    taskCardDataset.sort((a, b) => a.id - b.id);
    const maxId = taskCardDataset.length > 0 ? taskCardDataset[taskCardDataset.length - 1].id : 0;

    // ToDo: This is a temporary fix. need to refactor this later
    const newId = maxId + 100;

    newTaskCard = {
      ...newTaskCard,
      id: newId,
      serviceId: service.id,
      isNew: true
    };

    if (taskType === TaskType.LABOUR_TIME
      || taskType === TaskType.TRAVEL_TIME
      || taskType === TaskType.STAGING_TIME
      || taskType === TaskType.LOADING_TIME
    ) {
      newTaskCard = {
        ...newTaskCard,
        taskTypeCode: taskType,
        taskData: {
          duration: '00:00',
          notes: ''
        }
      };
    } else if (taskType === TaskType.TRAVEL_DISTANCE || taskType === TaskType.RESOURCE_UTILISED) {
      newTaskCard = {
        ...newTaskCard,
        taskTypeCode: taskType,
        taskData: {
          quantity: 0,
          notes: ''
        }
      };
    } else if (taskType === TaskType.QUOTE_VALUE) {
      newTaskCard = {
        ...newTaskCard,
        taskTypeCode: taskType,
        taskData: {
          valueCollection: 0,
          issuedTo: '',
          contactEmail: '',
          isApproved: false,
          notes: '',
        }
      };
    }
    taskCardDataset.push(newTaskCard);
  };
  
  useEffect(() => {
    if (getSuggestedNodesFromAllocationEngine.data && isUserHasPermission(ActionPermissions.Service_Edit_Allocation_Primary, getUserPermissionsQuery.data)) {
      const data = getSuggestedNodesFromAllocationEngine.data;
      data.forEach(item => item.isSuggestedNode = true);
      setSuggestedNodesFromAllocation(data);
    }
    else {
      setSuggestedNodesFromAllocation([]);
    }
  }, [getSuggestedNodesFromAllocationEngine.data, getUserPermissionsQuery.data]);

  useEffect(() => {
    if ((!isPendingInteractions && checkSetParameterNoUIFormAvailability(interceptedFormsObject?.forms) &&
      (interceptedFormsObject?.payload?.execute || interceptedFormsObject?.payload?.execute === undefined)) ||
      checkGetGeoLocationFormAvailability(interceptedFormsObject?.forms) || checkModifyStructureToggleActiveFormAvailability(interceptedFormsObject?.forms)
    ) {
      setIsFormSubmitting(true);
      setTimeout(() => {
        handleOnSubmitInteractionForms();
      }, 1500);
    }
    if (interceptedFormsObject?.forms && !originalInterceptedForms.length) {
      const originalForms = [...interceptedFormsObject.forms];
      setOriginalInterceptedForms(originalForms);
    }
  }, [interceptedFormsObject?.forms]);

  useEffect(() => {
    if (interceptedFormsObject?.actionTriggers?.length && !interceptedFormsObject.forms.length) {
      setIsFormSubmitting(true);
      setTimeout(() => {
        handleOnSubmitInteractionForms();
      }, 1500);
    }
    isCheckInCheckOut && onChangeInterceptedFormsObject(interceptedFormsObject?.forms && interceptedFormsObject.forms.length > 0 || false);
  }, [interceptedFormsObject]);

  useEffect(() => {
    isCancelOrReopen && getServiceCancelOrReopenStatuses.refetch();
    !isCancelOrReopen && getServiceCancelOrReopenStatuses.remove();
  }, [isCancelOrReopen]);

  useEffect(() => {
    isCancelOrReopen && getServiceCancelOrReopenStatuses.data && setIsStatusChange(!isStatusChange);
  }, [getServiceCancelOrReopenStatuses.data]);

  useEffect(() => {
    setTimeout(() => {
      !submitInteractions && newServiceId !== 0 && navigate(generateServiceURL(newServiceId));
    }, 1500);
  }, [submitInteractions]);

  useEffect(() => {
    if (interceptedFormsObject?.forms && repeatableFormData.templateId !== 0 && repeatableFormData.templateTitle !== '') {
      const newForm = originalInterceptedForms.find((form) => form.templateId === repeatableFormData.templateId && form.title === repeatableFormData.templateTitle);
      const updatedForms = !pendingInteractionFormData ? [...interceptedFormsObject.forms] : [...pendingInteractionFormData];
      newForm && updatedForms.push({ ...newForm, id: Math.max(...updatedForms.map((form) => form.id)) + 1 });
      setInterceptedFormsObject({ ...interceptedFormsObject, forms: updatedForms });
      pendingInteractionFormData && setPendingInteractionFormData(updatedForms);
    }
    (repeatableFormData.templateId !== 0 || repeatableFormData.templateTitle !== '') && setRepeatableFormData({ templateId: 0, templateTitle: '' });
  }, [repeatableFormData]);

  useEffect(() => {
    isStatusChange && onSubmit();
  }, [statusValue]);

  useEffect(() => {
    if (isCheckInCheckOut && !isCheckIn && !isCheckOut) {
      setInterceptedFormsObject(undefined);
      setHasInterceptedForms(false);
      setIsFormSubmitting(false);
      setOriginalInterceptedForms([]);
      setIsCheckInCheckOut('');
    }
  }, [isCheckIn, isCheckOut]);

  useEffect(() => {
    isCheckInCheckOut === CheckInCheckOutType.Checkin && onCheckIn();
    isCheckInCheckOut === CheckInCheckOutType.Checkout && onCheckOut();
    isCheckInCheckOut && setIsStatusChange(false);
  }, [isCheckInCheckOut]);

  useEffect(() => {
    hasInterceptedForms && isCheckInCheckOut && onChangeInterceptedFormsObject(interceptedFormsObject?.forms && interceptedFormsObject.forms.length > 0 || false);
  }, [hasInterceptedForms]);

  useEffect(() => {
    pendingInteractionFormData?.length && !isPendingInteractions && setIsPendingInteractions(true);
  }, [pendingInteractionFormData]);

  useEffect(() => {
    if (getPendingInteractionsByServiceId.data && !pendingInteractionFormData?.length) {
      getServiceLogsByServiceIdQuery.refetch();
      getPartActionsByServiceId.refetch();
      getTasksByServiceIdQuery.refetch();
    }
  }, [getPendingInteractionsByServiceId.data]);

  const onPartSave = async () => {
    if(partTypeValue && selectedPartActionData){
      const imageData: any[] = [];
      if (partImages) {
        const formData = new FormData();
        partImages.deviceImage && formData.append('fileList', partImages.deviceImage.file);
        partImages.labelImage && formData.append('fileList', partImages.labelImage.file);
        const fileUploadResponse = await uploadFiles(service.id, formData, Folders.ServiceAttachments, ServiceAttachmentUploadFeature.PartActionPartCreation);
        Object.keys(fileUploadResponse).forEach(key => {
          if(fileUploadResponse[key].dbSuccess){
            imageData.push(fileUploadResponse[key]);
          }
        });
      }
      const part = {
        partTypeId: partTypeValue.partTypeId,
        serial1: selectedPartActionData.newPartSerial,
        conditionCode: selectedPartActionData.action == Action.IN ? PartConditionOptions.GOOD : PartConditionOptions.FAULTY,
        serviceId: service.id,
        exception: true,
        isAccepted: false,
        partActionType: selectedPartActionData.partActionType,
        imageData,
        isActive: false
      };

      const response = await createPartQuery.mutateAsync(part);
      setOpenPartCreationPopup(false);
      if(response){
        onExceptionPartCreated(response);
      }
    }
  };

  const onExceptionPartCreated = (response: any) => {
    if(partTypeValue && selectedPartActionData){
      const updatedPartData = {
        partTypeId: response.partTypeId,
        partId: response.id,
        name: partTypeValue.name,
        category: partTypeValue.categoryCode,
        condition: response.conditionCode,
        conditionName: selectedPartActionData.action === Action.IN ? PartConditionName.Good : PartConditionName.Faulty,
        serial: response.serial1,
        location: response.locationName,
        serviceId: service.id,
        hasException: response.additionalData?.exception || false
      };

      let partAction: any;

      if(selectedPartActionData.partActionType === PartActionType.INSTALL){
        partAction = {
          outValue: selectedPartActionData.outValue,
          inValue: selectedPartActionData.inValue,
          value: {
            ...selectedPartActionData.value,
            ...updatedPartData
          }
        };
      } else {
        if(selectedPartActionData.action === Action.IN){
          partAction = {
            value: selectedPartActionData.value,
            inValue: {
              ...selectedPartActionData.inValue,
              id: selectedPartActionData.inValue?.id || 0,
              ...updatedPartData
            },
            outValue: selectedPartActionData.outValue
          };
        } else{
          partAction = {
            value: selectedPartActionData.value,
            outValue: {
              ...selectedPartActionData.outValue,
              id: selectedPartActionData.outValue?.id || 0,
              locationId: response.locationId,
              ...updatedPartData
            },
            inValue: selectedPartActionData.inValue
          };
        }
      }

      const index = partActionCardDataset.findIndex((obj) => obj.value.id === partAction.value.id);
      const newDataSet = partActionCardDataset.filter((obj) => obj.value.id !== partAction.value.id);

      const updatedCard: CustomisePartActionCard = {
        value: partAction.value,
        outValue: partAction.outValue || partAction.value,
        inValue: partAction.inValue || partAction.value
      };

      newDataSet.splice(index, 0, updatedCard);
      onChangePartActionCard(newDataSet);
    }
  };

  const handleSubmit = () => {
    if (isDropDownEmpty(statusValue)) {
      setValidate(true);
      return;
    }

    setIsStatusChange(false);
    setIsCancelOrReopen('');
    onSubmit(true);
    setAnchorEl(null);
    onNoteText('');
    setHasInterceptedForms(false);
    onChangeStatusValue('-1');
  };

  const clickPendingInteractions = () => {
    if (getPendingInteractionsByServiceId.data?.length) {
      const formsWithDisplayOrder = getPendingInteractionsByServiceId.data.filter((form) => form.displayOrder);
      const formsWithoutDisplayOrder = getPendingInteractionsByServiceId.data.filter((form) => !form.displayOrder);
      setPendingInteractionFormData(getSortedPendingInteractions(formsWithDisplayOrder, formsWithoutDisplayOrder));
    }
  };

  return (
    <ContentPaper elevation={1}>
      {isStatusChange || isPartAction || isTask || isPendingInteractions ?
        <CloseIconWrapStack direction="row">
          <CloseStatusChangeButton
            variant={ButtonVariantProps.Icon}
            size={ButtonSizeProps.Large}
            onClick={() => {
              if (isStatusChange) {
                setIsStatusChange(!isStatusChange);
                setIsCancelOrReopen('');
                onNoteText('');
                setInterceptedFormsObject(undefined);
                setHasInterceptedForms(false);
                setIsFormSubmitting(false);
                setOriginalInterceptedForms([]);
                setIsCheckInCheckOut('');
              }

              if (isPartAction) {
                setIsPartAction(false);
              }

              if (isTask) {
                setIsTask(false);
              }

              if (isPendingInteractions) {
                setIsPendingInteractions(false);
                setPendingInteractionFormData(undefined);
                setInterceptedFormsObject(undefined);
                setHasInterceptedForms(false);
                setOriginalInterceptedForms([]);
              }

              setAnchorEl(null);
            }}
          >
            <CancelIcon />
          </CloseStatusChangeButton>
        </CloseIconWrapStack>
        :
        <></>
      }
      {!isCheckInCheckOut &&
      <ActionBarStack direction="row" isStatusChange={isStatusChange} justifyContent="space-between" alignItems="center" spacing={2} width="100%">
        <Stack width="100%">
          {isTask ? (
            <Grid container rowSpacing={2} columnSpacing={{ sm: 4 }} pr={1}>
              {taskCardDataset.sort((a, b) => a.id - b.id).map((card: TaskCard, index: number) => (
                <Grid key={index} item xs={11.5} sm={12} md={6} xl={4}>
                  {(
                    card.taskTypeCode === TaskType.LABOUR_TIME ||
                    card.taskTypeCode === TaskType.TRAVEL_TIME ||
                    card.taskTypeCode === TaskType.STAGING_TIME ||
                    card.taskTypeCode === TaskType.LOADING_TIME
                  ) && (
                    <TaskTimeCard
                      data={card}
                      onChange={(taskCardObject: TaskCard) => onChangeTaskCard(taskCardObject)}
                      onSave={() => onSaveTaskCard(card)}
                      onExec={() => onExecTaskCard(card)}
                      onClose={() => onCloseAndChangePopupTaskCard(card)}
                    />
                  )}
                  {(
                    card.taskTypeCode === TaskType.TRAVEL_DISTANCE ||
                    card.taskTypeCode === TaskType.RESOURCE_UTILISED
                  ) && (
                    <TaskQuantityCard
                      data={card}
                      onChange={(taskCardObject: TaskCard) => onChangeTaskCard(taskCardObject)}
                      onSave={() => onSaveTaskCard(card)}
                      onExec={() => onExecTaskCard(card)}
                      onClose={() => onCloseAndChangePopupTaskCard(card)}
                    />
                  )}
                  {card.taskTypeCode === TaskType.QUOTE_VALUE && (
                    <TaskQuoteValueCard
                      data={card}
                      onChange={(taskCardObject: TaskCard) => onChangeTaskCard(taskCardObject)}
                      onSave={() => onSaveTaskCard(card)}
                      onExec={() => onExecTaskCard(card)}
                      onClose={() => onCloseAndChangePopupTaskCard(card)}
                    />
                  )}
                </Grid>
              ))}
              <Grid item xs={12} sm={12} md={6} xl={4}>
                <AddButton
                  startIcon={<AddOutlined />}
                  variant={ButtonVariantProps.Secondary}
                  color={ButtonColorProps.Success}
                  onClick={() => setIsTaskPopup(true)}
                  fullWidth
                > </AddButton>
              </Grid>
              <PopupDialog
                width="85%"
                maxWidth="400px"
                title="Tasks"
                isOpen={isTaskPopup}
                onClose={(event?: any, reason?: string) => (reason !== 'backdropClick') && setIsTaskPopup(false)}
                onSave={onPopupTaskSave}
              >
                <StyledSelectMenu
                  id="tasks-menu"
                  label="Select Task"
                  minWidth="100%"
                  items={TASK_ITEMS}
                  defaultSelectedValue={taskType}
                  onChange={(type: string) => setTaskType(type as TaskType)}
                />
              </PopupDialog>
            </Grid>
          ) : isPartAction ? (
            <Grid container rowSpacing={2} columnSpacing={{ sm: 4 }} pr={1}>
              {partActionCardDataset.map((card) => (
                <Grid key={card.value.id} item xs={11.5} sm={12} md={6} xl={4}>
                  {card.value.type === PartActionType.INSTALL && (
                    <PartActionInstallCard
                      service={service}
                      value={card.value}
                      onChange={(val: CustomisePartActionCard | PartCreationOptionData) => {
                        if((val as any).isPartCreation){
                          setSelectedPartActionData(val as PartCreationOptionData);
                          setPartTypeSearchValue(undefined);
                          setOpenPartCreationPopup(true);
                        } else {
                          val = val as CustomisePartActionCard;
                          const index = partActionCardDataset.findIndex((obj) => obj.value.id === card.value.id);
                          const newDataSet = partActionCardDataset.filter((obj) => obj.value.id !== card.value.id);
  
                          const updatedCard: CustomisePartActionCard = {
                            value: val.value,
                            inValue: card.inValue,
                            outValue: card.outValue
                          };
  
                          newDataSet.splice(index, 0, updatedCard);
                          onChangePartActionCard(newDataSet);
                        }
                      }}
                      onSave={() => onSavePartActionCard({ value: card.value })}
                      onExec={async () => {
                        setHelperText({
                          ...helperText,
                          in: {
                            ...helperText.in,
                            partTypeHelperText: !card.value.partTypeId ? PART_TYPE_CANNOT_EMPTY : '',
                            partSerialHelperText: !card.value.serial ? PART_SERIAL_CANNOT_EMPTY : '',
                            locationHelperText: !card.value.ns_partId && !card.value.location ? LOCATION_CANNOT_EMPTY : ''
                          },
                        });

                        let isSuccess = !!card.value.partTypeId && (!!card.value.serial || (!!card.value.ns_partId && !!card.value.location));
                        if (isSuccess) {

                          if (card.value.partId) {
                            isSuccess = await checkPartIsInPendingShipment(card);
                          }

                          if(isSuccess) {
                            onExecPartActionCard({ value: card.value });
                          }
                        }
                      }}
                      onClose={() => handlePartActionCardClose(card)}
                      helperText={helperText}
                      setHelperText={setHelperText}
                    />
                  )}
                  {card.value?.type === PartActionType.REPLACE &&
                    <PartActionReplaceCard
                      service={service}
                      value={card}
                      onChange={(customisedPartAction: CustomisePartActionCard | PartCreationOptionData) => {
                        if ((customisedPartAction as any).isPartCreation) {
                          setSelectedPartActionData(customisedPartAction as PartCreationOptionData);
                          setPartTypeSearchValue(undefined);
                          setOpenPartCreationPopup(true);
                        } else {
                          customisedPartAction = customisedPartAction as CustomisePartActionCard;
                          const partActionCardIndex = partActionCardDataset.findIndex((obj) => obj.value.id === card.value.id);
                          const filteredPartActionCardDataset = partActionCardDataset.filter((obj) => obj.value.id !== card.value.id);

                          const updatedCard: CustomisePartActionCard = {
                            value: card.value,
                            outValue: customisedPartAction.outValue || card.value,
                            inValue: customisedPartAction.inValue || card.value,
                            warning: customisedPartAction.inValue?.partTypeId !== customisedPartAction.outValue?.partTypeId ? triggerWarning(PartActionType.REPLACE, {
                              in: {
                                serial1: customisedPartAction.inValue?.serial,
                                partTypeName: customisedPartAction.inValue?.name
                              },
                              out: {
                                serial1: customisedPartAction.outValue?.serial,
                                partTypeName: customisedPartAction.outValue?.name
                              }
                            }) : ''
                          };

                          filteredPartActionCardDataset.splice(partActionCardIndex, 0, updatedCard);
                          onChangePartActionCard(filteredPartActionCardDataset);
                        }
                      }}
                      onSave={() => onSavePartActionCard({ value: card.value, outValue: card.outValue, inValue: card.inValue })}
                      onExec={() => {
                        setHelperText({
                          ...helperText,
                          in: {
                            ...helperText.in,
                            partTypeHelperText: !card?.inValue?.partTypeId ? PART_TYPE_CANNOT_EMPTY : '',
                            partSerialHelperText: !card?.inValue?.serial ? PART_SERIAL_CANNOT_EMPTY : '',
                          },
                          out: {
                            ...helperText.out,
                            partTypeHelperText: !card?.outValue?.partTypeId ? PART_TYPE_CANNOT_EMPTY : '',
                            partSerialHelperText: !card?.outValue?.serial ? PART_SERIAL_CANNOT_EMPTY : '',
                            noteHelperText: !card?.outValue?.notes ? NOTE_CANNOT_EMPTY : ''
                          }
                        });
                        
                        const isInSuccess = !!card?.inValue?.partTypeId && (!!card?.inValue?.serial || getPartTypeActionLiveSearchSerialiseType(card?.inValue?.category) === PartTypeActionLiveSearchSerialiseType.NonSerialised);
                        const isOutSuccess = !!card?.outValue?.partTypeId && (!!card?.outValue?.serial || getPartTypeActionLiveSearchSerialiseType(card?.outValue?.category) === PartTypeActionLiveSearchSerialiseType.NonSerialised) && !!card?.outValue?.notes;
                        isInSuccess && isOutSuccess && onExecPartActionCard({ value: card.value, outValue: card.outValue, inValue: card.inValue });
                      }}
                      onClose={() => handlePartActionCardClose(card)}
                      helperText={helperText}
                      setHelperText={setHelperText}
                    />
                  }
                  {card.value?.type === PartActionType.REMOVE &&
                    <PartActionRemoveCard
                      service={service}
                      value={card}
                      onChange={(val: CustomisePartActionCard | PartCreationOptionData) => {
                        if ((val as any).isPartCreation) {
                          setSelectedPartActionData(val as PartCreationOptionData);
                          setPartTypeSearchValue(undefined);
                          setOpenPartCreationPopup(true);
                        } else {
                          val = val as CustomisePartActionCard;
                          const index = partActionCardDataset.findIndex((obj) => obj.value.id === card.value.id);
                          const newDataSet = partActionCardDataset.filter((obj) => obj.value.id !== card.value.id);

                          const updatedCard: CustomisePartActionCard = {
                            value: card.value,
                            outValue: val.outValue || card.value,
                            inValue: val.inValue || card.value
                          };

                          newDataSet.splice(index, 0, updatedCard);
                          onChangePartActionCard(newDataSet);
                        }
                      }}
                      onSave={() => onSavePartActionCard({ value: card.value, outValue: card.outValue, inValue: card.inValue })}
                      onExec={() => {
                        setHelperText({
                          ...helperText,
                          out: {
                            ...helperText.out,
                            partTypeHelperText: !card?.outValue?.partTypeId ? PART_TYPE_CANNOT_EMPTY : '',
                            partSerialHelperText: !card?.outValue?.serial ? PART_SERIAL_CANNOT_EMPTY : '',
                            locationHelperText: !card?.outValue?.ns_partId && !card?.outValue?.location ? LOCATION_CANNOT_EMPTY : ''
                          },
                        });

                        const isSuccess = !!card?.outValue?.partTypeId && (!!card?.outValue?.serial || (!!card?.outValue?.ns_partId && !!card?.outValue?.location));
                        isSuccess && onExecPartActionCard({ value: card.value, outValue: card.outValue, inValue: card.inValue });
                      }}
                      onClose={() => handlePartActionCardClose(card)}
                      helperText={helperText}
                      setHelperText={setHelperText}
                    />
                  }
                  {card.value?.type === PartActionType.CLEAN && (
                    <PartActionOtherCard
                      title="Clean"
                      service={service}
                      value={card.value}
                      onChange={(val: CustomisePartActionCard) => {
                        const index = partActionCardDataset.findIndex((obj) => obj.value.id === card.value.id);
                        const newDataSet = partActionCardDataset.filter((obj) => obj.value.id !== card.value.id);

                        const updatedCard: CustomisePartActionCard = {
                          value: val.value,
                          inValue: card.inValue,
                          outValue: card.outValue
                        };

                        newDataSet.splice(index, 0, updatedCard);
                        onChangePartActionCard(newDataSet);
                      }}
                      onSave={() => onSavePartActionCard({ value: card.value })}
                      onExec={() => {
                        setHelperText({
                          ...helperText,
                          in: {
                            ...helperText.in,
                            partTypeHelperText: !card.value.partTypeId ? PART_TYPE_CANNOT_EMPTY : '',
                            partSerialHelperText: !card.value.serial ? PART_SERIAL_CANNOT_EMPTY : ''
                          },
                        });

                        const isSuccess = !!card.value.partTypeId && !!card.value.serial;
                        isSuccess && onExecPartActionCard({ value: card.value });
                      }}
                      onClose={() => handlePartActionCardClose(card)}
                      helperText={helperText}
                      setHelperText={setHelperText}
                    />
                  )}
                  {card.value?.type === PartActionType.TEST && (
                    <PartActionOtherCard
                      title="Test"
                      service={service}
                      value={card.value}
                      onChange={(val: CustomisePartActionCard) => {
                        const index = partActionCardDataset.findIndex((obj) => obj.value.id === card.value.id);
                        const newDataSet = partActionCardDataset.filter((obj) => obj.value.id !== card.value.id);

                        const updatedCard: CustomisePartActionCard = {
                          value: val.value,
                          inValue: card.inValue,
                          outValue: card.outValue
                        };

                        newDataSet.splice(index, 0, updatedCard);
                        onChangePartActionCard(newDataSet);
                      }}
                      onSave={() => onSavePartActionCard({ value: card.value })}
                      onExec={() => {
                        setHelperText({
                          ...helperText,
                          in: {
                            ...helperText.in,
                            partTypeHelperText: !card.value.partTypeId ? PART_TYPE_CANNOT_EMPTY : '',
                            partSerialHelperText: !card.value.serial ? PART_SERIAL_CANNOT_EMPTY : ''
                          },
                        });

                        const isSuccess = !!card.value.partTypeId && !!card.value.serial;
                        isSuccess && onExecPartActionCard({ value: card.value });
                      }}
                      onClose={() => handlePartActionCardClose(card)}
                      helperText={helperText}
                      setHelperText={setHelperText}
                    />
                  )}
                  {card.value?.type === PartActionType.DOA &&
                    <PartActionDoaCard
                      service={service}
                      value={card}
                      onChange={(val: CustomisePartActionCard) => {
                        const index = partActionCardDataset.findIndex((obj) => obj.value.id === card.value.id);
                        const newDataSet = partActionCardDataset.filter((obj) => obj.value.id !== card.value.id);

                        const updatedCard: CustomisePartActionCard = {
                          value: card.value,
                          outValue: val.outValue || card.value,
                          inValue: val.inValue || card.value
                        };

                        newDataSet.splice(index, 0, updatedCard);
                        onChangePartActionCard(newDataSet);
                      }}
                      onSave={() => onSavePartActionCard({ value: card.value, outValue: card.outValue, inValue: card.inValue })}
                      onExec={() => {
                        setHelperText({
                          ...helperText,
                          out: {
                            ...helperText.out,
                            partTypeHelperText: !card?.outValue?.partTypeId ? PART_TYPE_CANNOT_EMPTY : '',
                            partSerialHelperText: !card?.outValue?.serial ? PART_SERIAL_CANNOT_EMPTY : '',
                            locationHelperText: !card?.outValue?.ns_partId && !card?.outValue?.location ? LOCATION_CANNOT_EMPTY : ''
                          },
                        });

                        const isSuccess = !!card?.outValue?.partTypeId && (!!card?.outValue?.serial || (!!card?.outValue?.ns_partId && !!card?.outValue?.location));
                        isSuccess && onExecPartActionCard({ value: card.value, outValue: card.outValue, inValue: card.inValue });
                      }}
                      onClose={() => handlePartActionCardClose(card)}
                      helperText={helperText}
                      setHelperText={setHelperText}
                    />
                  }
                </Grid>
              ))}
              <Grid item xs={12} sm={12} md={6} xl={4}>
                <AddButton
                  startIcon={<AddOutlined />}
                  variant={ButtonVariantProps.Secondary}
                  color={ButtonColorProps.Success}
                  onClick={() => setIsPartActionPopup(true)}
                  fullWidth
                > </AddButton>
              </Grid>
              <PopupDialog
                width="85%"
                maxWidth="400px"
                title="Part Actions"
                isOpen={isPartActionPopup}
                onClose={(event?: any, reason?: string) => (reason !== 'backdropClick') && setIsPartActionPopup(false)}
                onSave={onPopupSave}
              >
                <StyledSelectMenu
                  id="part-actions-menu"
                  label="Select Part Actions"
                  minWidth="100%"
                  items={getPartActionTypesDropdownData(getPartActionTypes.data || [])}
                  selectedValue={partActionCardType}
                  onChange={(type: string) => setPartActionCardType(type as PartActionType)}
                />
              </PopupDialog>
            </Grid>
          ) : (
            isPendingInteractions ?
              <Grid container spacing={GRID_SPACING} direction="column">
                {pendingInteractionFormData?.map((form, index) => (
                  <Grid key={index} item sm={12} md={6} xl={4}>
                    <FormInterceptor
                      key={index}
                      service={service}
                      value={form}
                      onChange={(editedForms) => {
                        if (repeatableFormData.templateId === 0 && repeatableFormData.templateTitle === '') {
                          const updatedForms = pendingInteractionFormData.map((form) => form.id === editedForms.id ? editedForms : form);
                          setPendingInteractionFormData(updatedForms);
                          setInterceptedFormsObject({
                            url: `/pendingInteractions/${service.id}`,
                            method: HttpMethod.Put,
                            payload: '',
                            forms: updatedForms
                          });
                        }
                      }}
                      onDamagedPartSelect={(Objectkey: string, objectValue: FormData | number | MerchantDamagePartSerialMenuData) => {
                        setMerchantDamageObjectParcer(prevState => ({ ...prevState, [Objectkey]: objectValue }));
                      }}
                      partActionData={partActionData}
                      onAddForm={(templateId, templateTitle) => setRepeatableFormData({ templateId, templateTitle })}
                      isRepeatable={getFormIsRepeatableStatus(form, pendingInteractionFormData)}
                      forms={pendingInteractionFormData || []}
                    />
                  </Grid>
                ))}
                <div>
                  <br />
                  {isFormSubmitting ?
                    <Loader />
                    :
                    <Button
                      variant={ButtonVariantProps.Primary}
                      color={ButtonColorProps.Success}
                      onClick={handleOnSubmitInteractionForms}
                    >
                     Submit
                    </Button>
                  }
                </div>
              </Grid>
              :
              <Grid container spacing={2} justifyContent="space-between" alignItems="flex-end">
                <Grid item xs={12} sm={12} md={3}>
                  <Stack direction="row">
                    {isStatusChange &&
                      <SelectStatusContainer direction="row">
                        <ForwardIcon fontSize="large" color="action" />
                        <Stack width="100%">
                          <SelectMenu
                            id="service-status-menu"
                            label="Select Status"
                            items={statusOptionItems(isCancelOrReopen && getServiceCancelOrReopenStatuses.data ? getServiceCancelOrReopenStatuses.data : statusOptions, !service.serviceStatus.isClosed, isCancelOrReopen)}
                            selectedValue={statusValue}
                            onChange={(val) => {
                              onChangeStatusValue(val);
                            }}
                            disabled={!isUserHasPermission(ActionPermissions.Service_Edit_Status_Progress_Status_Change, permissions)}
                            validate={validate}
                            optionalLabelEnabled={true}
                            optionalLabel="Select Status"
                          />
                        </Stack>
                      </SelectStatusContainer>
                    }
                  </Stack>
                </Grid>
                {isStatusChange &&
                  <>
                    <Grid item xs={12} md={3} mb={1.5}>
                      {!!suggestedOptions.length && isCancelOrReopen === '' && <SuggestedText>Suggested Options</SuggestedText>}
                      <Grid container spacing={1} alignItems="flex-start">
                        {isCancelOrReopen === '' && suggestedOptions?.map((obj) => {
                          return (
                            <Grid item key={obj.code}>
                              <Button
                                color={ButtonColorProps.Warning}
                                onClick={() => {
                                  onChangeStatusValue(obj.code);
                                }}
                              >
                                {obj.name}
                              </Button>
                            </Grid>
                          );
                        })}
                        {isCancelOrReopen === '' && <Grid item>
                          {isCheckInEnabled &&
                            <Button
                              color={ButtonColorProps.Warning}
                              variant={ButtonVariantProps.Secondary}
                              onClick={() => setIsCheckInCheckOut(CheckInCheckOutType.Checkin)}
                              disabled={!isUserHasPermission(ActionPermissions.Service_Edit_Status_Progress_Status_Change, permissions)}
                            >
                              Check In
                            </Button>
                          }
                          {isCheckOutEnabled &&
                            <Button
                              color={ButtonColorProps.Warning}
                              variant={ButtonVariantProps.Secondary}
                              onClick={() => setIsCheckInCheckOut(CheckInCheckOutType.Checkout)}
                              disabled={!isUserHasPermission(ActionPermissions.Service_Edit_Status_Progress_Status_Change, permissions)}
                            >
                              Check Out
                            </Button>
                          }
                        </Grid>
                        }
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={5} justifyContent="flex-start">
                      <Stack direction="row">
                        <NotesText
                          label={isCancelOrReopen === ServiceCancelOrReOpen.ReOpen && noteText === '' ? 'Provide a note to re-open the service' : 'Notes'}
                          multiline
                          rows={2}
                          value={noteText}
                          onChange={(text) => onNoteText(text)}
                        />
                        {!interceptedFormsObject &&
                        <SubmitButton
                          variant={ButtonVariantProps.Secondary}
                          onClick={handleSubmit}
                          disabled={(isCancelOrReopen === ServiceCancelOrReOpen.ReOpen && noteText === '')}
                        >
                          <DoneOutlineOutlined fontSize="large" color="success" />
                        </SubmitButton>
                        }
                      </Stack>
                    </Grid>
                  </>
                }
                {isAllocationChange &&
                  <ServiceAllocationSelector
                    allocation={selectedNodeAllocation}
                    defaultAllocationSuggetions={suggestedNodesFromAllocation}
                    onSubmit={(val) => {
                      onAllocationChange(val);
                      setIsAllocationChange(false);
                    }}
                    onClose={() => setIsAllocationChange(false)}
                    permissions={permissions}
                  />
                }
                {isStatusChange || isAllocationChange || isCheckInCheckOut ||
                  <>
                    {isHelpdeskUser && currentStatusLabel === ServiceStatusLabel.OnSite ?
                      <>
                        {technicians.slice(0, 2).map((technician: Technician) =>
                          <Stack direction="row" alignItems="center" spacing={1} key={technician.id}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <IconContainer backgroundColor="#1769AA1F"><AccountIcon /></IconContainer>
                              <AccountText>{technician.name}</AccountText>
                            </Stack>
                          </Stack>
                        )}
                        <Grid container spacing={1} justifyContent="space-between">
                          <Grid item xs={12} md={4}>
                            <MenuButton
                              aria-describedby="service-status-menu"
                              variant="text"
                              color="inherit"
                              onClick={(event) => setAnchorElTechnicians(event.currentTarget)}
                            >
                              <IconContainer backgroundColor="#1769AA1F"><AccountIcon /></IconContainer>
                            </MenuButton>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Menu
                              id="technician-list-menu"
                              anchorEl={anchorElTechnicians}
                              open={openTechniciansMenu}
                              onClose={() => setAnchorElTechnicians(null)}
                              MenuListProps={{ 'aria-labelledby': 'technician-list-menu' }}
                            >
                              {technicians.map((technician: Technician) =>
                                <MenuItem key={technician.id}>{technician.name}</MenuItem>
                              )}
                            </Menu>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <MenuButton
                              aria-describedby="service-status-menu"
                              variant="text"
                              color="inherit"
                              onClick={(event) => setAnchorEl(event.currentTarget)}
                            >
                              <MenuIcon />
                            </MenuButton>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Badge 
                              color="secondary" 
                              variant="dot" 
                              invisible={!partActionCardDataset.length || !isUserHasPartActionPermissions(permissions, isCheckIn, service.serviceStatusCode)} 
                              style={{ transform: 'translate(6px, 6px)' }}
                            >
                              <Menu
                                id="service-status-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={() => setAnchorEl(null)}
                                MenuListProps={{ 'aria-labelledby': 'service-status-menu' }}
                              >
                                {isUserHasPartActionPermissions(permissions, isCheckIn, service.serviceStatusCode) &&
                                  <FullWidthBadge color="secondary" invisible={!partActionCardDataset.length} badgeContent={partActionCardDataset.length} overlap="circular" className="css">
                                    <MenuItem onClick={() => setIsPartAction(!isPartAction)}>Part Actions</MenuItem>
                                  </FullWidthBadge>
                                }
                                {isUserHasPermission(ActionPermissions.Service_Edit_Actions_Tasks, permissions) && <MenuItem onClick={() => setIsTask(!isTask)}>Tasks</MenuItem>}
                              </Menu>
                            </Badge>
                          </Grid>
                        </Grid>
                      </>
                      :
                      <Stack direction="row" width="100%">
                        <Grid container display="flex" justifyContent="flex-start">
                          {!isStatusChange && !isAllocationChange && !isCheckInCheckOut &&
                            <Grid item xs={12} sm={6} md={4} display="flex" justifyContent={{ xs: 'center', md: 'flex-start' }} alignItems="center"> 
                              <NewButton
                                startIcon={<LightbulbIcon />}
                                variant={ButtonVariantProps.Secondary}
                                onClick={() => !service.serviceStatus.isClosed && setIsStatusChange(!isStatusChange)}
                              >
                                {currentStatusLabel}
                              </NewButton>
                            </Grid>
                          }
                          {isUserHasPermission(ActionPermissions.Service_Edit_Allocation_Primary, permissions) && 
                            <Grid item xs={12} sm={6} md={4} display="flex" justifyContent={{ xs: 'center', md: 'flex-start' }} alignItems="center"> 
                              {primaryAllocation && primaryAllocation.node ?
                                <ClickableStack direction="row" alignItems="flex-end" spacing={1} onClick={() => {
                                  setIsAllocationChange(true);
                                  setSelectedNodeAllocation({
                                    ...primaryAllocation,
                                    allocationType: ServiceNodeAllocationType.Primary
                                  });
                                }}>
                                  <StyledActionBarStack isMobile={isMobileView}>
                                    <IconContainer backgroundColor="transparent"><PrimaryAllocationIcon /></IconContainer>
                                    {primaryAllocation.parentNode && primaryAllocation.parentNode.id > 0 ?
                                      <Stack direction="column">
                                        <LocationText>{primaryAllocation.parentNode.name}</LocationText>
                                        <Stack direction="row">
                                          <SubdirectoryArrowIcon />
                                          <LocationSubText>{primaryAllocation.node.name}</LocationSubText>
                                        </Stack>
                                      </Stack>
                                      :
                                      <Stack direction="row" alignItems="center" >
                                        <LocationText>{primaryAllocation.node.name}</LocationText>
                                      </Stack>
                                    }
                                  </StyledActionBarStack>
                                </ClickableStack>
                                :
                                <Stack direction="row" alignItems="flex-end" spacing={1}>
                                  <StyledActionBarAllocateStack>
                                    <IconContainer backgroundColor="transparent"><PrimaryAllocationIcon /></IconContainer>
                                    <Button
                                      color={ButtonColorProps.Warning}
                                      variant={ButtonVariantProps.Secondary}
                                      onClick={() => {
                                        setIsAllocationChange(true);
                                        setSelectedNodeAllocation({
                                          ...primaryAllocation,
                                          allocationType: ServiceNodeAllocationType.Primary
                                        });
                                      }}>
                                      Allocate
                                    </Button>
                                  </StyledActionBarAllocateStack>
                                </Stack>
                              } 
                            </Grid> 
                          }
                          {isUserHasPermission(ActionPermissions.Service_Edit_Allocation_Secondary, permissions) && 
                            <Grid item xs={12} sm={6} md={4} display="flex" justifyContent={{ xs: 'center', md: 'flex-start' }} alignItems="center"> 
                              {secondaryAllocation && secondaryAllocation.node ?
                                <ClickableStack direction="row" alignItems="flex-end" spacing={1} onClick={() => {
                                  setIsAllocationChange(true);
                                  setSelectedNodeAllocation({
                                    ...secondaryAllocation,
                                    allocationType: ServiceNodeAllocationType.Secondary
                                  });
                                }}>
                                  <StyledActionBarStack isMobile={isMobileView}>
                                    <IconContainer backgroundColor="transparent"><SecondaryAllocationIcon /></IconContainer>
                                    {secondaryAllocation.parentNode && secondaryAllocation.parentNode.id > 0 ?
                                      <Stack direction="column">
                                        <LocationText>{secondaryAllocation.parentNode.name}</LocationText>
                                        <Stack direction="row">
                                          <SubdirectoryArrowIcon />
                                          <LocationSubText>{secondaryAllocation.node.name}</LocationSubText>
                                        </Stack>
                                      </Stack>
                                      :
                                      <Stack direction="column">
                                        <LocationText>{secondaryAllocation.node.name}</LocationText>
                                      </Stack>
                                    }
                                  </StyledActionBarStack>
                                </ClickableStack>
                                :
                                <Stack direction="row" alignItems="flex-end" spacing={1}>
                                  <StyledActionBarAllocateStack>
                                    <IconContainer backgroundColor="transparent"><SecondaryAllocationIcon /></IconContainer>
                                    <Button
                                      color={ButtonColorProps.Warning}
                                      variant={ButtonVariantProps.Secondary}
                                      onClick={() => {
                                        setIsAllocationChange(true);
                                        setSelectedNodeAllocation({
                                          ...secondaryAllocation,
                                          allocationType: ServiceNodeAllocationType.Secondary
                                        });
                                      }}>
                                      Allocate
                                    </Button>
                                  </StyledActionBarAllocateStack>
                                </Stack>
                              } 
                            </Grid> 
                          }
                        </Grid>
                        <Stack direction="row" alignItems="center" justifyContent="center">
                          <MenuButton
                            aria-describedby="service-status-menu"
                            variant="text"
                            color="inherit"
                            onClick={(event) => setAnchorEl(event.currentTarget)}
                          >
                            <MenuIcon />
                          </MenuButton>
                          <Badge 
                            color="secondary" 
                            variant="dot" 
                            invisible={(!partActionCardDataset.length && !taskCardDataset.length && !getPendingInteractionsByServiceId.data?.length) || !isUserHasPartActionPermissions(permissions, isCheckIn, service.serviceStatusCode)} 
                            style={{ transform: 'translate(6px, 6px)' }}
                          >
                            <Menu
                              id="service-status-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={() => setAnchorEl(null)}
                              MenuListProps={{ 'aria-labelledby': 'service-status-menu' }}
                              sx={{ width: '50%' }}
                            >
                              {isUserHasPartActionPermissions(permissions, isCheckIn, service.serviceStatusCode) &&
                                <FullWidthBadge color="secondary" invisible={!partActionCardDataset.length} badgeContent={partActionCardDataset.length} overlap="circular" className="css">
                                  <FullWidthMenuItem onClick={() => setIsPartAction(!isPartAction)}><HandymanIcon sx={{ marginRight: '8px' }}/>Part Actions</FullWidthMenuItem>
                                </FullWidthBadge>
                              }
                              {isUserHasPermission(ActionPermissions.Service_Edit_Actions_Tasks, permissions) &&
                                <FullWidthBadge color="secondary" invisible={!taskCardDataset.length} badgeContent={taskCardDataset.length} overlap="circular" className="css">
                                  <FullWidthMenuItem onClick={() => setIsTask(!isTask)}><ViewInArIcon sx={{ marginRight: '8px' }}/>Tasks</FullWidthMenuItem>
                                </FullWidthBadge>
                              }
                              <FullWidthBadgeInteraction color="secondary" invisible={!getPendingInteractionsByServiceId.data?.length} badgeContent={getPendingInteractionsByServiceId.data?.length} overlap="circular" className="css">
                                <FullWidthMenuItem disabled={!getPendingInteractionsByServiceId.data?.length} onClick={clickPendingInteractions}><RocketLaunchIcon sx={{ marginRight: '8px' }}/>Pending Interactions</FullWidthMenuItem>
                              </FullWidthBadgeInteraction>
                              {checkServiceCancelOrFutileAvailability(permissions || [], isCheckIn, service.serviceStatusCode) && !service.serviceStatus.isClosed &&
                                <MenuItem onClick={() => setIsCancelOrReopen(ServiceCancelOrReOpen.CancelFutile)}><DisabledByDefaultOutlinedIcon sx={{ marginRight: '8px' }}/>Cancel/Futile</MenuItem>
                              }
                              {isUserHasPermission(ActionPermissions.Service_Edit_Status_Progress_Reopen_Service, permissions) && service.serviceStatus.isClosed &&
                                <MenuItem onClick={() => setIsCancelOrReopen(ServiceCancelOrReOpen.ReOpen)}><ZapSquareIcon />Re-Open</MenuItem>
                              }
                            </Menu>
                          </Badge>
                        </Stack>
                      </Stack>
                    }
                  </>
                }
              </Grid>
          )}
        </Stack>
      </ActionBarStack>
      }
      {!pendingInteractionFormData && (interceptedFormsObject?.payload?.execute || interceptedFormsObject?.payload?.execute === undefined) &&
        interceptedFormsObject?.forms?.map((form, index) =>
          <FormInterceptor
            key={index}
            service={service}
            value={form}
            onChange={(editedForms) => {
              if (repeatableFormData.templateId === 0 && repeatableFormData.templateTitle === '') {
                // TODO: Remove this log after interactions completed.
                // console.log('FormInterceptor logs', editedForms);
                const updatedForms = interceptedFormsObject?.forms.map((formData) => formData.id === editedForms.id ? editedForms : formData);
                setInterceptedFormsObject({
                  ...interceptedFormsObject,
                  forms: updatedForms
                });
              }
            }}
            onDamagedPartSelect={(Objectkey: string, objectValue: FormData | number | MerchantDamagePartSerialMenuData) => {
              setMerchantDamageObjectParcer(prevState => ({ ...prevState, [Objectkey]: objectValue }));
            }}
            partActionData={partActionData}
            onAddForm={(templateId, templateTitle) => setRepeatableFormData({ templateId, templateTitle })}
            isRepeatable={getFormIsRepeatableStatus(form, interceptedFormsObject.forms)}
            forms={interceptedFormsObject.forms}
          />
        )}
      {!pendingInteractionFormData && interceptedFormsObject &&
        (interceptedFormsObject?.payload?.execute || interceptedFormsObject?.payload?.execute === undefined) &&
        <div>
          <br />
          {isFormSubmitting ?
            <Loader />
            :
            <Button
              variant={ButtonVariantProps.Primary}
              color={ButtonColorProps.Success}
              onClick={handleOnSubmitInteractionForms}
              disabled={isCancelOrReopen === ServiceCancelOrReOpen.ReOpen && noteText === ''}
            >
              Submit
            </Button>
          }
        </div>
      }
      <PopupDialog
        width="50%"
        maxWidth="300px"
        title="Pending Shipment"
        isOpen={openShipmentReceivePopup}
        actionLabel="Accept"
        cancelButtonLable="Cancel"
        onClose={(reason) => {
          if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
            setOpenShipmentReceivePopup(false);
            return;
          }
          setOpenShipmentReceivePopup(false);

          const partActionCard = partActionCardDataset.find(partAction => partAction.value.id === selectedPartActionCard?.value.id);
          const newDataSet = partActionCardDataset.filter((obj) => obj.value.id !== selectedPartActionCard?.value.id);

          if(partActionCard) {
            const updatedCard: CustomisePartActionCard = {
              value: { ...partActionCard.value, partId: NaN, serial: '' },
              inValue: selectedPartActionCard?.inValue,
              outValue: selectedPartActionCard?.outValue
            };

            newDataSet.splice(partActionCardDataset.indexOf(partActionCard), 0, updatedCard);
            onChangePartActionCard(newDataSet);

            if(selectedPartActionCard?.value.actionId) {
              onSavePartActionCard({ value: { ...partActionCard.value, partId: NaN, serial: '' } });
            }
          }
        }}
        onSave={async () => {
          await receiveShipment();
          selectedPartActionCard && onExecPartActionCard({ value: selectedPartActionCard.value });
          setOpenShipmentReceivePopup(false);
        }}
      >
        <PartActionShipmentReceive shipmentManifestSummery={shipmentManifestSummery} />
      </PopupDialog>
      <PopupDialog
        width="90%"
        maxWidth="500px"
        title="Create Serialized Part"
        isOpen={openPartCreationPopup}
        actionLabel="Submit"
        cancelButtonLable="Cancel"
        onClose={() => setOpenPartCreationPopup(false)}
        onSave={() => onPartSave()}
      >
        <PartActionPartCreationDialog
          isOpen={openPartCreationPopup}
          helperText={helperText}
          partActionData={selectedPartActionData}
          partTypeImages={partTypeImages}
          onPartImagesUpdate={setPartImages}
          setPartTypeSearchValue={setPartTypeSearchValue}
          partTypeValue={partTypeValue}
          service={service}
        ></PartActionPartCreationDialog>
      </PopupDialog>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={showWarningToast} autoHideDuration={8000} onClose={() => setShowWarningToast(false)}>
        <Alert onClose={() => setShowWarningToast(false)} severity="warning">
          <AlertTitle>Warning</AlertTitle>
          Service creation form has errors and continuing will not create a service. Please fill the required fields.
        </Alert>
      </Snackbar>
    </ContentPaper>
  );
};